import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Link, NavLink } from 'react-router-dom'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import Card from '@mui/material/Card';

const Home = () => {
  const navigate= useNavigate();



  
  return (
    <>
         <div className="main-container container home" >
        <div className="row my-4 text-center">
    
        <div className="col-12">
        <p><img src="Assets/img/icons/wallet.png" height="40" width="40"/></p>
                    <h3 className=" mb-2" style={{"color" : "#664acc"}}>
                      {/* <i className="fas fa-rupee-sign"></i> */}
                      <CurrencyRupeeIcon />
                      2000</h3>
      </div>
      </div>



      <div className="row mb-4 services">
      <div className="col-4 px-1 hvr-bounce-in">
      <NavLink className="nav-link " aria-current="page" style={{'textDecoration':'none'}} to="/firstform">
      {/* <div className="card card1"> */}
      <Card variant="outlined" className="card card1">
                        <div className="card-body">
                          
                        <div className="col-auto text-center " >
                                   
                                   <div className="icons  text-white">
                                     {/* <i className="fas fa-shopping-bag"></i> */}
                                     <ShoppingBagIcon  style={{'fontSize':'37px'}}/>
                                      <span>Order</span>
                                   </div>
                              
                           </div>
                        </div>
                        {/* </div> */}
                        </Card>
</NavLink>


      </div>

      {/* *********  second  */}

      <div className="col-4 px-1 hvr-bounce-in">
      <NavLink className="nav-link " aria-current="page" style={{'textDecoration':'none'}} to="/firstform">
      {/* <div className="card card2"> */}
      <Card variant="outlined" className="card card2">

                        <div className="card-body">
                          
                        <div className="col-auto text-center " >
                                   
                                   <div className="icons  text-white">
                                   <ShoppingBagIcon  style={{'fontSize':'37px'}}/>

                                      <span>Order</span>
                                   </div>
                              
                           </div>
                        </div>
                        {/* </div> */}
                        </Card>

</NavLink>


      </div>

       {/* *********  three  */}

       <div className="col-4 px-1 hvr-bounce-in">
      <NavLink className="nav-link " aria-current="page" style={{'textDecoration':'none'}} to="/firstform">
      {/* <div className="card card3"> */}
      <Card variant="outlined" className="card card3">

                        <div className="card-body">
                          
                        <div className="col-auto text-center " >
                                   
                                   <div className="icons  text-white">
                                   <ShoppingBagIcon  style={{'fontSize':'37px'}}/>

                                      <span>Profile</span>
                                   </div>
                              
                           </div>
                        </div>
                        {/* </div> */}
                        </Card>

</NavLink>


      </div>
      </div>


 {/* *********  four  */}
 <div class="row mb-4 services">
 <div className="col-4 px-1 hvr-bounce-in">
      <NavLink className="nav-link " aria-current="page" style={{'textDecoration':'none'}} to="/firstform">
      <Card variant="outlined" className="card card5">

                        <div className="card-body">
                          
                        <div className="col-auto text-center " >
                                   
                                   <div className="icons  text-white">
                                   <ShoppingBagIcon  style={{'fontSize':'37px'}}/>

                                      <span>Order History</span>
                                   </div>
                              
                           </div>
                        </div>
                        </Card>

</NavLink>


      </div>

       {/* *********  five  */}

       <div className="col-4 px-1 hvr-bounce-in">
      <NavLink className="nav-link " aria-current="page" style={{'textDecoration':'none'}} to="/firstform">
      <Card variant="outlined" className="card card4">

                        <div className="card-body">
                          
                        <div className="col-auto text-center " >
                                   
                                   <div className="icons  text-white">
                                   <ShoppingBagIcon  style={{'fontSize':'37px'}}/>

                                      <span>Estimate History</span>
                                   </div>
                              
                           </div>
                        </div>
                        </Card>

</NavLink>


      </div>

       {/* *********  six  */}

       <div className="col-4 px-1 hvr-bounce-in">
      <NavLink className="nav-link " aria-current="page" style={{'textDecoration':'none'}} to="/firstform">
      <Card variant="outlined" className="card card6">

                        <div className="card-body">
                          
                        <div className="col-auto text-center " >
                                   
                                   <div className="icons  text-white">
                                   <ShoppingBagIcon  style={{'fontSize':'37px'}}/>

                                      <span>Stock</span>
                                   </div>
                              
                           </div>
                        </div>
                        </Card>

</NavLink>


      </div>

      </div>


      <div className="row">
      <div className="col-12 col-md-6 col-lg-4">
      <NavLink className="nav-link card mb-3 "  to="/firstform">

      <div className="card-body">
                            <div className="row">
                                <div className="col-auto">
                                    <div className="avatar avatar-60 shadow-sm rounded-10 coverimg">
                                    <img src="Assets/img/user2.png" alt=""/>

                              </div> 
                              </div> 


                              <div className="col align-self-center ps-0">
                              <p className="mb-1">Admin</p>

                                    <p className="text-muted size-12">2022</p>
                                </div>


                              </div> 
                              </div> 
                                                                

      </NavLink>


      </div> 
      </div> 

      </div> 


    </>
  )
}

export default Home
