import React from 'react'
import { Link, NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
            <ul className="nav nav-pills nav-justified">
                <li className="nav-item">

                <NavLink className="nav-link active"   to="/Home">
                <span>
                            <i className="fas fa-home"></i>
                            <span className="nav-text">Home</span>
                        </span>

              </NavLink>
                   
                </li>
                <li className="nav-item">

                <NavLink className="nav-link "   to="/Firstform">
                <span>
                <i className="fas fa-shopping-bag"></i>
                <span className="nav-text">Order</span>
                        </span>

              </NavLink>

                    
                </li>
                <li className="nav-item centerbutton">
                    <button type="button" className="nav-link" 
                        id="centermenubtn">
                        <span className="theme-radial-gradient">
                            <i className="bi bi-grid size-22"></i>
                        </span>
                    </button>
                </li>
                <li className="nav-item">

                <NavLink className="nav-link "   to="/Firstform">
                <span>
                <i className="fab fa-accusoft"></i>
                <span className="nav-text">Estimation</span>
                        </span>

              </NavLink>


                    
                </li>
                <li className="nav-item">

                <NavLink className="nav-link "   to="/Firstform">
                <span>
                <i className="fab fa-accusoft"></i>
                <span className="nav-text">Profile</span>
                        </span>

              </NavLink>

                    
                </li>
            </ul>
        </div>
    </footer>
    </>

    

)
}

export default Footer