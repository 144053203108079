import logo from './logo.svg';
import './App.css';

import React, {useState} from 'react';
// import Users from './Users';
// import UsersClassComp from './UsersClassComp';
// import States from './States';
// import CompHookClass from './CompHookClass';
// import CompProps from './CompProps';
// import ClassProps from './ClassProps';
// import GetInputBox from './GetInputBox';
// import FormHandling from './FormHandling';
// import ConditionalRendrings from './ConditionalRendrings';
// import FormValidation from './FormValidation';
// import FunctionToBePaassed from './FunctionToBePaassed';
// import ClassLifeCycle from './ClassLifeCycle';
// import Hook from './Hook';
// import CssTypes from './CssTypes';
// import Bootstrap from './Bootstrap';
// import LiftingState from './LiftingState';
// import LiftingChildCompB from './LiftingChildCompB';
// import ClassPureComponent from './ClassPureComponent';
// import Memos from './Memos';
// import UseMemos from './UseMemos';
// import FunUseRef from './FunUseRef';
// import ForwordREf from './ForwordREf';
// import ControlleredComp from './ControlleredComp';
// import UncontrolledComp from './UncontrolledComp';
// import HighOrderComp from './HighOrderComp';
// import UseCallbacks from './UseCallbacks';
// import UseReducer from './UseReducer';
// import UseContextHook from './UseContextHook';
import Navbar from './Navbar';




function App() {






// ***888 example 3
// const[myname ,setName]=useState('technicle');



  // example2
// const userData = [
//    {name:"ram", age:25, email:'sk@gmail.com'},
//    {name:"ganesh", age:26, email:'sk@gmail.com'},
//    {name:"riya", age:25, email:'sk@gmail.com'},
 

// ]

{/* *************** Function to be Passed Props*********** */}
// exmple 1
// const handleButtonClick = () =>{
//   console.log("function to be passed props");
// }

// exmple 2
// const[count, setCount] = useState(0);

// const incrementCount = ()=>{
//   setCount(count+1);
// }


// ***************** class Render method ********************
// const[Newname, setNewName] =useState("sk");

  {/* ********Lifting State **************** */}
  // const data = 'snehal';

  // const[liftingdata , setLiftingdata] = useState("");

  // const handleChange = (newValue)=>{
  //    setLiftingdata(newValue);
  //   }

  // **************  Memo **************
  // const[memocount,setMemoCount]=useState(1);


  // const MemosCount=()=>{
  //    setMemoCount(memocount+1);
  // }

  

  return (
    <div className="App">
{/* *************** React Routing ************ */}
<Navbar/>


{/* ********** useReducer Hook ************** */}
{/* <UseContextHook/> */}
{/* ********** useReducer Hook ************** */}
{/* <UseReducer/> */}

{/* ********** usecall back Hook ************** */}

{/* <UseCallbacks/> */}


{/* ********** uncontrolled component ************** */}
{/* <HighOrderComp/> */}
{/* ********** uncontrolled component ************** */}

{/* <UncontrolledComp/> */}
{/* ********** controlled component ************** */}
{/* <ControlleredComp/> */}
     
{/* ********** forword Ref ************** */}
{/* <ForwordREf/> <br /> */}

{/* ********** use Ref ************** */}
{/* <FunUseRef/>  <br /> */}
{/* ************* Use Memo************* */}

{/* <UseMemos/> <br /> */}


{/* <Memos memocount={memocount}/> */}
{/* <button onClick={MemosCount}>Memo Btn</button> */}
     {/* *  class pure component */}
     {/* <ClassPureComponent/> */}


{/* <LiftingState liftingdata={liftingdata} handleChange={handleChange}/> */}
{/* <LiftingChildCompB liftingdata={liftingdata}/> */}

{/* <LiftingChildCompB liftingdata={liftingdata}/> */}

   
{/* <Bootstrap/> */}
{/* <CssTypes/> */}

     {/* <h1>Hello world <Users/> </h1> */}
     {/* <Hook/> */}
{/* <br>
</br> */}

{/* Function to be Passed Props  send function data one component to another component */}
{/* <FunctionToBePaassed handleClick={handleButtonClick} count={count} counter={incrementCount}/> */}



{/* <ClassLifeCycle name={Newname}/> */}

{/* <button onClick={()=>setNewName('pk')}>Update Render Button</button> */}

{/* <FormValidation/> */}
    {/* <GetInputBox/> */}
    {/* <FormHandling/> */}
    {/* <br /> */}
{/* <ConditionalRendrings/> */}

     {/* <h2> <UsersClassComp/> </h2> */}
     {/* <h4> <States/> </h4> */}
     {/* <br /> */}

{/* ****************** start props************** */}
     {/* example 1 */}

     {/* <CompProps name="snehal"/> */}

     {/* <CompProps name={myname}/> */}


     {/* example 2 */}
    {/* {
       userData.map((user, index)=>(
         <CompProps key={index}
         name={user.name}
         age={user.age}
         email={user.email} />
       ))} */}

       {/* example 3 */}

       {/* <button onClick={()=>setName('myDemo')}>Update props</button> */}
    


{/* <CompHookClass/> */}

{/* <br /> */}
{/* <ClassProps name="sk"/> */}
    </div>
  );
}

export default App;
