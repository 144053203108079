import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button';

import CreateIcon from '@mui/icons-material/Create';

import{ Table }from"antd";
const DetailsView = () => {
  const navigate= useNavigate();

  const handleNavigation = () => {

    navigate('/firstform');
  };


  const dataSource = [
    {
      key: '1',
      // action:<CreateIcon />,
      name: 'Mike',
      age: 32,
      address: '10 Downing Street',
    },
    {
      key: '2',
      name: 'John',
      age: 42,
      address: '10 Downing Street',
    },
  ];
  
  const columns = [
    // {
    //   title: 'Action',
    //   dataIndex: 'action',
    //   key: 'action',
    // },

    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
  ];
  return (
    <>

<div className="col-11 col-sm-11  col-md-8 col-lg-8 col-xl-8 mx-auto align-self-center py-1">

<div className="d-flex justify-content-end">



<Button onClick={handleNavigation} variant="contained"  className='btn py-2 btn-default btn-lg shadow-sm addbtn1' style={{'textDecoration':'none' , 'fontSize' : '16px' ,'color' : 'white',"textTransform" : "capitalize" ,"height" : "33px"  }}>Add New</Button>



</div>


{/* <div class="table-responsive  mt-3">
    <table class="table table-bordered table-striped text-center ">
                                               
                                               

                                           <thead>
                                              <th style={{"border" : "1px solid #dee2e6 "}}>Action</th>
                                              <th style={{"border" : "1px solid #dee2e6 "}}>ID</th>

                                                 <th style={{"border" : "1px solid #dee2e6 "}}>FARMERS NAME</th>
                                                    <th style={{"border" : "1px solid #dee2e6 "}}>ADDRESS</th> 
                                                    <th style={{"border" : "1px solid #dee2e6 "}}>MOBILE NO</th>
                                                   

</thead>


                                              <tbody>
                                          <tr>
                                            <td><CreateIcon style={{"fontSize " : "16px"}} /> </td>
                                            <td>1</td>
                                            <td>abc</td>
                                            <td>xyz</td>
                                            <td>4563453675</td>


                                          </tr>

                                          <tr>
                                            <td><CreateIcon style={{"fontSize " : "16px"}} /> </td>
                                            <td>1</td>
                                            <td>abc</td>
                                            <td>xyz</td>
                                            <td>4563453675</td>


                                          </tr>
                                        </tbody>

                       
</table>

</div> */}

<Table className="mt-3" dataSource={dataSource} columns={columns} />;
</div>
</>
  )
}

export default DetailsView
