import React from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

const FirstForm = () => {
  return (
    <>
<div className="col-11 col-sm-11  col-md-8 col-lg-8 col-xl-8 mx-auto align-self-center py-1">

<center>
              <label className="mb-4 text-center"style={{"color" : "#404447","fontFamily" : "Poppins!important"}}> <h5>Feed Dose Chart</h5></label>
              </center> 

              <form id="Form" action=""  method="POST"  >
              <div className="form-group form-floating mb-2 is-valid">

              {/* <input type="text" className="form-control" value="" id="farmersName" placeholder="farmersName" name="farmersName" /> */}

              <TextField id="standard-basic" label="Farmers Name" variant="standard" />

              {/* <label className="form-control-label" for="farmersName">Farmers Name</label> */}

            </div>

            <div className="form-group form-floating mb-2 is-valid">

{/* <textarea className="form-control" value="" id="address" placeholder="address" name="address"></textarea>
<label className="form-control-label" for="mobileNo">Address</label> */}
              <TextField
          id="standard-multiline-static"
          label="Address"
          multiline
          rows={2}
          defaultValue=""
          variant="standard"
        />

</div>

<div className="form-group form-floating mb-2 is-valid">

  {/* <input type="text" className="form-control" value="" id="mobileNo" placeholder="mobileNo" name="mobileNo" maxlength="10"/>
                    <label className="form-control-label" for="mobileNo">Mobile No</label> */}
                       <TextField id="standard-basic"  type="number" label="Farmers Name" variant="standard" className='col-12' />

</div>

<div className="form-group form-floating mb-2 is-valid">

  {/* <select className="form-control">
    <option value="">abc</option>
    <option value="">abcg</option>

  </select> */}
  <TextField className='col-12'
          id="standard-select-currency"
          select
          label="Select"
          defaultValue=""
          // helperText="Please select your currency"
          variant="standard"
        >
          <MenuItem key={1} value={1}>
            abc
            </MenuItem>
            <MenuItem key={1} value={1}>
            abc
            </MenuItem>
          {/* {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))} */}
        </TextField>

</div>

     <div className="col-5 col-sm-5 mt-auto mx-auto py-4" id="saveBox">
                <div className="row ">
                    <div className="col-12 d-grid">
                         {/* <button type="button" className="btn py-2 btn-default btn-lg shadow-sm" id="btn_save" name="btn_save" style={{"fontSize" : "18px"}}>Save</button> */}

                         <Button variant="contained" className="btn py-2 btn-default btn-lg shadow-sm" style={{"fontSize" : "18px" , "color" : "white"}}>Save</Button>
                        
                    </div>
                </div>
            </div>        
</form>
  </div>

    </>
  )
}

export default FirstForm
