import  React from 'react'
import { BrowserRouter,Routes,Route } from 'react-router-dom'

import Home from './Home'
import FirstForm from './FirstForm'
import DetailsView from './DetailsView'

import NavbarHeader from './NavbarHeader'
import Footer from './Footer'
// import GetUrlParam from './GetUrlParam'


const Navbar = () => {
  return (
    <div>
      <BrowserRouter>
      <main className="h-100">
      <NavbarHeader/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/firstform' element={<FirstForm/>}/>
        <Route path='/DetailsView' element={<DetailsView/>}/>
        {/* <Route path='/user/:userId' element={<UserProfile/>}/>
        <Route path='/geturl' element={<GetUrlParam/>}/> */}


        <Route path='/*' element={<h1>404 Page Not Found</h1>}/>

      </Routes>
<Footer/>
      </main>
      </BrowserRouter>
    </div>
  )
}

export default Navbar
