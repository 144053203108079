import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import Button from '@mui/material/Button';

const NavbarHeader = () => {
  return (
    <>
<body className="body-scroll" data-page="index">
<div className="container-fluid loader-wrap">

<div className="row h-100">
<div className="col-10 col-md-6 col-lg-5 col-xl-3 mx-auto text-center align-self-center">

<p className="mt-4 mb-0"><span className="text-secondary"> 
<img src="Assets/img/nwlogo.png" alt="" style={{'height':'130px','width':'200px'}}   /></span></p>

<div className="preloader"></div>
  </div>
  </div>
</div>

<div className="sidebar-wrap  sidebar-overlay">
<div className="closemenu text-muted">Close Menu</div>
<div className="sidebar">
   

<div className="row my-3">
<div className="col-12 profile-sidebar">
<div className="clearfix"></div>
<div className="circle small one"></div>
<div className="circle small two"></div>

<svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 194.287 141.794"
    className="menubg"
  >
    <defs>
      <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#92b938" />
        <stop offset="1" stopColor="#f6b606" />
      </linearGradient>
    </defs>
    <path
      id="menubg"
      d="M672.935,207.064c-19.639,1.079-25.462-3.121-41.258,10.881s-24.433,41.037-49.5,34.15-14.406-16.743-50.307-29.667-32.464-19.812-16.308-41.711S500.472,130.777,531.872,117s63.631,21.369,93.913,15.363,37.084-25.959,56.686-19.794,4.27,32.859,6.213,44.729,9.5,16.186,9.5,26.113S692.573,205.985,672.935,207.064Z"
      transform="translate(-503.892 -111.404)"
      fill="#f6b606"
    />
  </svg>


                    <div className="row mt-3">
                    <div className="col-auto">

                    <figure className="avatar avatar-60 rounded-15 border">
                                        <img src="Assets/img/user2.png" alt="" className="w-100" /> 
                                    </figure>

                                    {/* <img id='imgpreview1' name='imgpreview1'  
                        src='Assets/img/user2.png' alt='user' className='img-thumbnail rounded-20' style={{'cursor': 'pointer;'}}/>  */}

                    </div>

                    <div className="col px-3 align-self-center">
                     
                    <h5 className="mb-2"> admin </h5>
                    <h6 className="mb-2">7875653434</h6>
                       
                    </div>                

                    </div>                
</div>
</div>

<div className="row">
<div className="col-12">

<ul className="nav nav-pills ">
<li className="nav-item" >

<NavLink className="nav-link  mynav" aria-current="page" style={{'textDecoration':'none'}} to="/">
<div className="avatar avatar-40 icon"><i className="fas fa-home"></i></div>
<div className="col">Dashboard</div>

</NavLink>

</li>


<li className="nav-item mynav"  >

<NavLink className="nav-link "  style={{'textDecoration':'none'}} to="/firstform">
<div className="avatar avatar-40 icon"><i className="fas fa-home"></i></div>
<div className="col">Order</div>

</NavLink>

</li>

<li className="nav-item mynav">

<NavLink className="nav-link  "  style={{'textDecoration':'none'}} to="/detailsview">
<div className="avatar avatar-40 icon"><i className="fas fa-home"></i></div>
<div className="col">Details View</div>

</NavLink>

</li>

</ul>


</div>
</div>


</div>

</div>



<header className="header position-fixed">
            <div className="row">
                <div className="col-4">
                    <a href="javascript:void(0)" target="_self" className="btn btn-light btn-44 menu-btn" style={{'fontSize':'23px'}}>
                        <i className="fas fa-bars icon" style={{"lineHeight": "40px!important"}}></i>
                    </a>

{/* <Button variant="contained" target="_self" className="btn btn-light btn-44 menu-btn" style={{'fontSize':'23px','maxWidth' : '30px!important'}}> <i className="fas fa-bars icon" style={{"lineHeight": "40px!important"}}></i></Button> */}

                </div>
                <div className="col-8 text-left">
                    <div className="logo-small">
                        <img src="Assets/img/nwlogo.png" alt="" />
                    </div>
                </div>
                {/* <div className="">
                 
                </div> */}
            </div>
        </header>
      

</body>



    </>
  )
}

export default NavbarHeader
